import './navbar.css';
import { NavLink, Link } from 'react-router-dom';
import Logo from '../componentgallery/Logo.png';
import { Fade } from "react-awesome-reveal";
const Navbar = () => {
  return (
    <>
      <div className='navbar'>
        <div className='logo'>
          <Link to='/'><img src={Logo} alt="" width='100%' height='100%' /></Link>
        </div>
        <div className='items'>
          {/* <NavLink to='/testimonial' activeClassName='active-link'><li>TESTIMONIAL</li></NavLink> */}
          <Fade>
            <ul>
              <NavLink to='/explore' activeClassName='active-link' ><li>EXPLORE</li></NavLink>
              <NavLink to='/contact' activeClassName='active-link'><li>CONTACT US</li></NavLink>
            </ul>
          </Fade>
        </div>
      </div>
    </>
  )
}

export default Navbar