import './footer.css';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Flogo from '../componentgallery/Logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className='footer_wrap'>
        <div className='footer'>
          <div className='copyright_logo'>
            <div className='footer_logo'>
              <img src={Flogo} alt="" width='100%' />
            </div>
          </div>
          <div className='social_media'>
            <div className='heading_social_media'>
              <p>Follow us</p>
            </div>
            <div className='icons_social_media_container'>
              <div className='icons_sm'><FaFacebookF /></div>
              <div className='icons_sm'><FaInstagram /></div>
            </div>
          </div>
          <div className='hyper_link'>
            <ul>
              {/* <Link to='/gallery' activeClassName='active-link' ><li>GALLERY</li></Link> */}
              {/* <li>Terms & Conditions</li> */}
              {/* <li>Privacy Policy</li> */}
              <Link to='/gallery' activeClassName='active-link' ><li>Contact Us</li></Link>
            </ul>
          </div>
        </div>
        <div className='copy_container'>
          <div className='copyright_content'><p>Copyright 	&#169; Travways Tours</p></div>
        </div>
      </div>
    </>
  )
}

export default Footer