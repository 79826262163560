import { useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import './gallery.css';

const Gallery = () => {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const importAll = (context) => context.keys().map(context);
        const importedImages = importAll(require.context(`./gallery/`, false, /\.(png|jpe?g|svg|JPG|webp|WEBP)$/));

        // Count loaded images
        let loadedCount = 0;
        const imagesToLoad = importedImages.length;

        importedImages.forEach((imageSrc) => {
            const img = new Image();
            img.onload = () => {
                loadedCount++;
                if (loadedCount === imagesToLoad) {
                    setImages(importedImages);
                    setImagesLoaded(true);
                }
            };
            img.src = imageSrc;
        });
    }, []);
    return (
        <>
            {/* <div className="gallery_heading_container">
                <div className="gallery_heading"><p>Gallery</p></div>
            </div> */}
            <div className="gallery_container_image">
                <div className="images_gallery_container">
                    {!imagesLoaded && <div><Loading /></div>}
                    {imagesLoaded && images.map((image, index) => (
                        <div className="images" key={index}>
                            <img src={image} alt={`Imge ${index + 1}`} width='100%' />
                            {/* Set fixed dimensions for the images */}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Gallery