import './App.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Loading from './components/loading/Loading';
import Gallery from './pages/gallery/Gallery';
import { Toaster } from 'react-hot-toast';
const Home = lazy(() => import('./pages/home/Home'));
const Explore = lazy(() => import('./pages/explore/Explore'));
const Testimonial = lazy(() => import('./pages/testimonial/Testimonial'));
const Contact = lazy(() => import('./pages/contact/Contact'));
function App() {
  return (
    <>
      <Suspense fallback={<Loading/>}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/home" element={<Home/>} />
            <Route path="/Explore" element={<Explore/>} />
            <Route path="/testimonial" element={<Testimonial/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/gallery" element={<Gallery/>} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Suspense>
      <Toaster/>
    </>
  );
}

export default App;
